.back-to-top {
  display: flex;
  width: rem(45);
  height: rem(45);

  position: fixed;
  bottom: 80px;
  right: 20px;

  z-index: 1;

  color: $color-white;

  justify-content: center;
  align-items: center;

  cursor: pointer;
  transform: translateY(100px);
  opacity: 0;
  background-color: $color-gold;
  border-radius: 50%;
  transition: transform 0.5s, opacity 0.3s;

  box-shadow: rgba(64, 70, 64, 0.25) 0 2px 5px;

  &.is-shown {
    transform: translateY(0);
    opacity: 1;
  }
}
