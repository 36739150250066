.pricing {
  &__images {
    display: flex;

    margin: rem(34) 0;

    flex-direction: column;

    @include breakpoint($tablet) {
      flex-direction: row;
      justify-content: space-between;
      gap: rem(24);
    }

    img {
      flex: 1;
      margin-bottom: rem(24);

      animation: fadeLeft 1s 1 cubic-bezier(0.77, 0, 0.175, 1);

      &:last-of-type {
        margin-bottom: 0;
      }

      @include breakpoint($tablet) {
        margin-bottom: 0;

        width: 49%;
      }
    }
  }

  &__capacity {
    display: flex;

    padding: rem(34) 0;
    margin: rem(50) 0;

    flex-direction: column;

    border-top: rem(1) solid rgba(148, 160, 148, 0.16);
    border-bottom: rem(1) solid rgba(148, 160, 148, 0.16);

    @include breakpoint($tablet) {
      flex-direction: row;
      align-items: center;
      gap: 8%;
    }

    h2 {
      margin-bottom: rem(24);

      text-align: center;

      @include breakpoint($tablet) {
        margin-bottom: 0;
        padding-left: 8%;

        text-align: left;
      }
    }
  }

  &__days {
    display: flex;

    margin-bottom: rem(34);
    padding-bottom: rem(50);

    flex-direction: column;
    align-items: center;

    border-bottom: rem(1) solid rgba(148, 160, 148, 0.16);

    @include breakpoint($tablet) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__day {
    display: flex;
    width: rem(250);
    height: rem(250);

    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #cadbdb;
    border-radius: 100%;

    &:not(:last-of-type) {
      margin-bottom: rem(20);
    }

    p {
      padding: 0 rem(25);

      text-align: center;
    }

    &-price {
      font-size: rem(24);
    }

    &-wrapper {
      &:not(:last-child) {
        margin-bottom: rem(20);
      }

      @include breakpoint($tablet) {
        display: flex;
        width: 50%;

        align-items: center;
        justify-content: center;

        margin-bottom: rem(20);

        &:not(:last-child) {
          margin-left: rem(25);
          margin-right: rem(25);
        }
      }

      @include breakpoint(1100px) {
        display: flex;
        width: auto;

        align-items: center;
        justify-content: center;

        &:not(:nth-last-child(-n+2)) {
          margin-bottom: rem(20);
        }
      }
    }
  }

  &__items {
    margin-bottom: rem(34);

    h2 {
      margin-bottom: rem(24);

      text-align: center;
    }

    &__columns {
      text-align: center;

      @include breakpoint($desktop) {
        column-count: 2;
        column-gap: 8.6%;
      }
    }
  }

  &__deluxe {
    &-group {
      display: flex;

      margin-bottom: rem(34);
      padding-bottom: rem(50);

      justify-content: center;

      border-bottom: rem(1) solid rgba(148, 160, 148, 0.16);
    }

    display: flex;
    width: 100%;
    max-width: rem(530);

    flex-direction: column;
    align-items: center;

    @include breakpoint(420px) {
      padding: rem(70) rem(20);

      background-color: #cadbdb;
      border-radius: 50%;
    }

    &__top,
    &__bottom {
      text-align: center;
    }

    &__top {
      padding-bottom: rem(20);
      margin-bottom: rem(20);

      border-bottom: rem(1) solid rgba(148, 160, 148, 0.5);

      span {
        display: inline-block;
        width: 100%;
      }
    }

    &__pricing {
      font-size: rem(30);
    }
  }


}
