$alert-colors: (
'danger'   : $alert-red,
'info'     : $alert-blue,
'warning'  : $alert-amber,
'success'  : $alert-green,
) !default;

.alert {
  margin: rem(10) 0;
  padding: rem(10);

  color: #424242;

  border-radius: rem(5);
}

@each $name, $color in $alert-colors {
  .alert-#{$name} {
    background: $color;
  }
}
