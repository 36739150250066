.room {
  display: flex;

  flex-direction: column;

  @include breakpoint($tablet) {
    flex-direction: row;
    gap: rem(34)
  }

  &__slider {
    @include breakpoint($tablet) {
      width: 60%;
    }
  }

  &__content {
    margin-top: rem(24);

    @include breakpoint($tablet) {
      margin-top: 0;

      flex: 1;
    }
  }

  &__text {
    &:not(:last-of-type) {
      margin-bottom: rem(32);
    }

    h2 {
      margin-bottom: rem(24);

      font-family: $font-main;
    }

    &.has-bullets {
      p {
        position: relative;

        padding-left: rem(20);

        &:before {
          content: '';
          display: block;
          width: rem(8);
          height: rem(8);

          position: absolute;
          left: 0;
          top: 16px;
          transform: translateY(-50%);

          background-color: $color-gold;
          border-radius: 50%;
        }
      }
    }

    &.has-pipes {
      p {
        display: inline;

        &:not(:last-of-type) {
          &:after {
            content: '|';
            display: inline-block;

            margin: 0 rem(10);

            color: $color-gold;
          }
        }
      }
    }
  }
}


.sp-bottom-thumbnails.sp-has-pointer .sp-selected-thumbnail:before {
  border-bottom: 5px solid $color-white;
}

.sp-bottom-thumbnails.sp-has-pointer .sp-selected-thumbnail:after {
  border-bottom: 13px solid $color-white;
}

.sp-button {
  border: rem(2) solid $color-gold;

  &.sp-selected-button {
    background-color: $color-gold;
  }
}

.sp-thumbnail {
  width: auto;
  height: 100%;

  cursor: pointer;

  img {
    width: auto;
    height: 100%;
    object-fit: cover;
  }
}
