.slider-pro {
  position: relative;
  margin: 0 auto;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.sp-slides-container {
  position: relative;
}

.sp-mask {
  position: relative;
  overflow: hidden;
}

.sp-slides {
  position: relative;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
}

.sp-slide {
  position: absolute;
}

.sp-image-container {
  overflow: hidden;
}

.sp-image {
  position: relative;
  display: block;
  border: none;
}

.sp-no-js {
  overflow: hidden;
  max-width: 100%;
}

/* Thumbnails
--------------------------------------------------*/
.sp-thumbnails-container {
  position: relative;
  overflow: hidden;
  direction: ltr;
}

.sp-top-thumbnails,
.sp-bottom-thumbnails  {
  left: 0;
  margin: 0 auto;
}

.sp-top-thumbnails {
  position: absolute;
  top: 0;
  margin-bottom: 4px;
}

.sp-bottom-thumbnails {
  margin-top: 4px;
}

.sp-left-thumbnails,
.sp-right-thumbnails {
  position: absolute;
  top: 0;
}

.sp-right-thumbnails {
  right: 0;
  margin-left: 4px;
}

.sp-left-thumbnails {
  left: 0;
  margin-right: 4px;
}

.sp-thumbnails {
  position: relative;
}

.sp-thumbnail {
  border: none;
}

.sp-thumbnail-container {
  position: relative;
  display: block;
  overflow: hidden;
  float: left;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.sp-rtl .sp-thumbnail-container {
  float: right;
}

/* Horizontal thumbnails
------------------------*/
.sp-top-thumbnails .sp-thumbnail-container,
.sp-bottom-thumbnails .sp-thumbnail-container {
  margin-left: 2px;
  margin-right: 2px;
}

.sp-top-thumbnails .sp-thumbnail-container:first-child,
.sp-bottom-thumbnails .sp-thumbnail-container:first-child {
  margin-left: 0;
}

.sp-top-thumbnails .sp-thumbnail-container:last-child,
.sp-bottom-thumbnails .sp-thumbnail-container:last-child {
  margin-right: 0;
}

/* Vertical thumbnails
----------------------*/
.sp-left-thumbnails .sp-thumbnail-container,
.sp-right-thumbnails .sp-thumbnail-container {
  margin-top: 2px;
  margin-bottom: 2px;
}

.sp-left-thumbnails .sp-thumbnail-container:first-child,
.sp-right-thumbnails .sp-thumbnail-container:first-child {
  margin-top: 0;
}

.sp-left-thumbnails .sp-thumbnail-container:last-child,
.sp-right-thumbnails .sp-thumbnail-container:last-child {
  margin-bottom: 0;
}

/* Right thumbnails with pointer
--------------------------------*/
.sp-right-thumbnails.sp-has-pointer {
  margin-left: -13px;
}

.sp-right-thumbnails.sp-has-pointer .sp-thumbnail {
  position: absolute;
  left: 18px;
  margin-left: 0 !important;
}

.sp-right-thumbnails.sp-has-pointer .sp-selected-thumbnail:before {
  content: '';
  position: absolute;
  height: 100%;
  border-left: 5px solid #F00;
  left: 0;
  top: 0;
  margin-left: 13px;
}

.sp-right-thumbnails.sp-has-pointer .sp-selected-thumbnail:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  top: 50%;
  margin-top: -8px;
  border-right: 13px solid #F00;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

/* Left thumbnails with pointer
-------------------------------*/
.sp-left-thumbnails.sp-has-pointer {
  margin-right: -13px;
}

.sp-left-thumbnails.sp-has-pointer .sp-thumbnail {
  position: absolute;
  right: 18px;
}

.sp-left-thumbnails.sp-has-pointer .sp-selected-thumbnail:before {
  content: '';
  position: absolute;
  height: 100%;
  border-left: 5px solid #F00;
  right: 0;
  top: 0;
  margin-right: 13px;
}

.sp-left-thumbnails.sp-has-pointer .sp-selected-thumbnail:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  right: 0;
  top: 50%;
  margin-top: -8px;
  border-left: 13px solid #F00;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

/* Bottom thumbnails with pointer
---------------------------------*/
.sp-bottom-thumbnails.sp-has-pointer {
  margin-top: -13px;
}

.sp-bottom-thumbnails.sp-has-pointer .sp-thumbnail {
  position: absolute;
  top: 18px;
  margin-top: 0 !important;
}

.sp-bottom-thumbnails.sp-has-pointer .sp-selected-thumbnail:before {
  content: '';
  position: absolute;
  width: 100%;
  border-bottom: 5px solid #F00;
  top: 0;
  margin-top: 13px;
}

.sp-bottom-thumbnails.sp-has-pointer .sp-selected-thumbnail:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  top: 0;
  margin-left: -8px;
  border-bottom: 13px solid #F00;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

/* Top thumbnails with pointer
------------------------------*/
.sp-top-thumbnails.sp-has-pointer {
  margin-bottom: -13px;
}

.sp-top-thumbnails.sp-has-pointer .sp-thumbnail {
  position: absolute;
  bottom: 18px;
}

.sp-top-thumbnails.sp-has-pointer .sp-selected-thumbnail:before {
  content: '';
  position: absolute;
  width: 100%;
  border-bottom: 5px solid #F00;
  bottom: 0;
  margin-bottom: 13px;
}

.sp-top-thumbnails.sp-has-pointer .sp-selected-thumbnail:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  bottom: 0;
  margin-left: -8px;
  border-top: 13px solid #F00;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

/* Layers
--------------------------------------------------*/
.sp-layer {
  position: absolute;
  margin: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-backface-visibility: hidden;
}

.sp-black {
  color: #FFF;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.7);
}

.sp-white {
  color: #000;
  background: rgb(255, 255, 255);
  background: rgba(255, 255, 255, 0.7);
}

.sp-rounded {
  border-radius: 10px;
}

.sp-padding {
  padding: 10px;
}

/* Caption
--------------------------------------------------*/
.sp-caption-container {
  text-align: center;
  margin-top: 10px;
}

/* Full Screen
--------------------------------------------------*/
.sp-full-screen {
  margin: 0 !important;
  background-color: #000;
}

.sp-full-screen-button {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 30px;
  line-height: 1;
  cursor: pointer;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sp-full-screen-button:before {
  content: '\2195';
}

.sp-fade-full-screen {
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.slider-pro:hover .sp-fade-full-screen {
  opacity: 1;
}

/* Buttons
--------------------------------------------------*/
.sp-buttons {
  position: relative;
  width: 100%;
  text-align: center;
  padding-top: 10px;
}

.sp-rtl .sp-buttons {
  direction: rtl;
}

.sp-full-screen .sp-buttons {
  display: none;
}

.sp-button {
  width: 10px;
  height: 10px;
  border: 2px solid #000;
  border-radius: 50%;
  margin: 4px;
  display: inline-block;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}

.sp-selected-button {
  background-color: #000;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .sp-button {
    width: 14px;
    height: 14px;
  }
}

@media only screen and (min-width: 568px) and (max-width: 768px) {
  .sp-button {
    width: 16px;
    height: 16px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 568px) {
  .sp-button {
    width: 18px;
    height: 18px;
  }
}

/* Arrows
--------------------------------------------------*/
.sp-arrows {
  position: absolute;
}

.sp-fade-arrows {
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.sp-slides-container:hover .sp-fade-arrows {
  opacity: 1;
}

.sp-horizontal .sp-arrows {
  width: 100%;
  left: 0;
  top: 50%;
  margin-top: -15px;
}

.sp-vertical .sp-arrows {
  height: 100%;
  left: 50%;
  top: 0;
  margin-left: -10px;
}

.sp-arrow {
  position: absolute;
  display: block;
  width: 20px;
  height: 30px;
  cursor: pointer;
}

.sp-vertical .sp-arrow {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sp-horizontal .sp-previous-arrow {
  left: 20px;
  right: auto;
}

.sp-horizontal.sp-rtl .sp-previous-arrow {
  right: 20px;
  left: auto;
}

.sp-horizontal .sp-next-arrow {
  right: 20px;
  left: auto;
}

.sp-horizontal.sp-rtl .sp-next-arrow {
  left: 20px;
  right: auto;
}

.sp-vertical .sp-previous-arrow {
  top: 20px;
}

.sp-vertical .sp-next-arrow {
  bottom: 20px;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sp-previous-arrow:before,
.sp-previous-arrow:after,
.sp-next-arrow:before,
.sp-next-arrow:after {
  content: '';
  position: absolute;
  width: 50%;
  height: 50%;
  background-color: #FFF;
}

.sp-arrow:before {
  left: 30%;
  top: 0;
  -webkit-transform: skew(145deg, 0deg);
  -ms-transform: skew(145deg, 0deg);
  transform: skew(145deg, 0deg);
}

.sp-arrow:after {
  left: 30%;
  top: 50%;
  -webkit-transform: skew(-145deg, 0deg);
  -ms-transform: skew(-145deg, 0deg);
  transform: skew(-145deg, 0deg);
}

.sp-next-arrow {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sp-horizontal.sp-rtl .sp-previous-arrow {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sp-horizontal.sp-rtl .sp-next-arrow {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.ie8 .sp-arrow,
.ie7 .sp-arrow {
  width: 0;
  height: 0;
}

.ie8 .sp-arrow:before,
.ie8 .sp-arrow:after,
.ie7 .sp-arrow:before,
.ie7 .sp-arrow:after {
  content: none;
}

.ie8.sp-horizontal .sp-previous-arrow,
.ie7.sp-horizontal .sp-previous-arrow {
  border-right: 20px solid #FFF;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.ie8.sp-horizontal .sp-next-arrow,
.ie7.sp-horizontal .sp-next-arrow {
  border-left: 20px solid #FFF;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.ie8.sp-vertical .sp-previous-arrow,
.ie7.sp-vertical .sp-previous-arrow {
  border-bottom: 20px solid #FFF;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}

.ie8.sp-vertical .sp-next-arrow,
.ie7.sp-vertical .sp-next-arrow {
  border-top: 20px solid #FFF;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}

/* Thumbnail Arrows
--------------------------------------------------*/
.sp-thumbnail-arrows {
  position: absolute;
}

.sp-fade-thumbnail-arrows {
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.sp-thumbnails-container:hover .sp-fade-thumbnail-arrows {
  opacity: 1;
}

.sp-top-thumbnails .sp-thumbnail-arrows,
.sp-bottom-thumbnails .sp-thumbnail-arrows {
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -12px;
}

.sp-left-thumbnails .sp-thumbnail-arrows,
.sp-right-thumbnails .sp-thumbnail-arrows {
  height: 100%;
  top: 0;
  left: 50%;
  margin-left: -7px;
}

.sp-thumbnail-arrow {
  position: absolute;
  display: block;
  width: 15px;
  height: 25px;
  cursor: pointer;
}

.sp-left-thumbnails .sp-thumbnail-arrows .sp-thumbnail-arrow,
.sp-right-thumbnails .sp-thumbnail-arrows .sp-thumbnail-arrow {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sp-top-thumbnails .sp-previous-thumbnail-arrow,
.sp-bottom-thumbnails .sp-previous-thumbnail-arrow {
  left: 0;
}

.sp-top-thumbnails .sp-next-thumbnail-arrow,
.sp-bottom-thumbnails .sp-next-thumbnail-arrow {
  right: 0;
}

.sp-left-thumbnails .sp-previous-thumbnail-arrow,
.sp-right-thumbnails .sp-previous-thumbnail-arrow {
  top: 0;
}

.sp-left-thumbnails .sp-next-thumbnail-arrow,
.sp-right-thumbnails .sp-next-thumbnail-arrow {
  bottom: 0;
}

.sp-previous-thumbnail-arrow:before,
.sp-previous-thumbnail-arrow:after,
.sp-next-thumbnail-arrow:before,
.sp-next-thumbnail-arrow:after {
  content: '';
  position: absolute;
  width: 50%;
  height: 50%;
  background-color: #FFF;
}

.sp-previous-thumbnail-arrow:before {
  left: 30%;
  top: 0;
  -webkit-transform: skew(145deg, 0deg);
  -ms-transform: skew(145deg, 0deg);
  transform: skew(145deg, 0deg);
}

.sp-previous-thumbnail-arrow:after {
  left: 30%;
  top: 50%;
  -webkit-transform: skew(-145deg, 0deg);
  -ms-transform: skew(-145deg, 0deg);
  transform: skew(-145deg, 0deg);
}

.sp-next-thumbnail-arrow:before {
  right: 30%;
  top: 0;
  -webkit-transform: skew(35deg, 0deg);
  -ms-transform: skew(35deg, 0deg);
  transform: skew(35deg, 0deg);
}

.sp-next-thumbnail-arrow:after {
  right: 30%;
  top: 50%;
  -webkit-transform: skew(-35deg, 0deg);
  -ms-transform: skew(-35deg, 0deg);
  transform: skew(-35deg, 0deg);
}

.ie8 .sp-thumbnail-arrow,
.ie7 .sp-thumbnail-arrow {
  width: 0;
  height: 0;
}

.ie8 .sp-thumbnail-arrow:before,
.ie8 .sp-thumbnail-arrow:after,
.ie7 .sp-thumbnail-arrow:before,
.ie7 .sp-thumbnail-arrow:after {
  content: none;
}

.ie8 .sp-top-thumbnails .sp-previous-thumbnail-arrow,
.ie8 .sp-bottom-thumbnails .sp-previous-thumbnail-arrow,
.ie7 .sp-top-thumbnails .sp-previous-thumbnail-arrow,
.ie7 .sp-bottom-thumbnails .sp-previous-thumbnail-arrow {
  border-right: 12px solid #FFF;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
}

.ie8 .sp-top-thumbnails .sp-next-thumbnail-arrow,
.ie8 .sp-bottom-thumbnails .sp-next-thumbnail-arrow,
.ie7 .sp-top-thumbnails .sp-next-thumbnail-arrow,
.ie7 .sp-bottom-thumbnails .sp-next-thumbnail-arrow {
  border-left: 12px solid #FFF;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
}

.ie8 .sp-left-thumbnails .sp-previous-thumbnail-arrow,
.ie8 .sp-right-thumbnails .sp-previous-thumbnail-arrow,
.ie7 .sp-left-thumbnails .sp-previous-thumbnail-arrow,
.ie7 .sp-right-thumbnails .sp-previous-thumbnail-arrow {
  border-bottom: 12px solid #FFF;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
}

.ie8 .sp-left-thumbnails .sp-next-thumbnail-arrow,
.ie8 .sp-right-thumbnails .sp-next-thumbnail-arrow,
.ie7 .sp-left-thumbnails .sp-next-thumbnail-arrow,
.ie7 .sp-right-thumbnails .sp-next-thumbnail-arrow {
  border-top: 12px solid #FFF;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
}

/* Video
--------------------------------------------------*/
a.sp-video {
  text-decoration: none;
}

a.sp-video img {
  -webkit-backface-visibility: hidden;
  border: none;
}

a.sp-video:after {
  content: '\25B6';
  position: absolute;
  width: 45px;
  padding-left: 5px;
  height: 50px;
  border: 2px solid #FFF;
  text-align: center;
  font-size: 30px;
  border-radius: 30px;
  top: 0;
  color: #FFF;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  margin: auto;
  line-height: 52px;
}

/* CSS for preventing styling issues in WordPress
--------------------------------------------------*/
.slider-pro img.sp-image,
.slider-pro img.sp-thumbnail {
  max-width: none;
  max-height: none;
  border: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  -webkit-box-shadow: none !important;
  -mox-box-shadow: none !important;
  box-shadow: none !important;
  transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  -o-transition: none;
}

.slider-pro a {
  position: static;
  transition: none !important;
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -o-transition: none !important;
}

.slider-pro iframe,
.slider-pro object,
.slider-pro video,
.slider-pro embed,
.slider-pro canvas {
  max-width: none;
  max-height: none;
}

.slider-pro p.sp-layer {
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
}

.slider-pro h1.sp-layer {
  font-size: 32px;
  line-height: 1.4;
  margin: 0;
}

.slider-pro h2.sp-layer {
  font-size: 24px;
  line-height: 1.4;
  margin: 0;
}

.slider-pro h3.sp-layer {
  font-size: 19px;
  line-height: 1.4;
  margin: 0;
}

.slider-pro h4.sp-layer {
  font-size: 16px;
  line-height: 1.4;
  margin: 0;
}

.slider-pro h5.sp-layer {
  font-size: 13px;
  line-height: 1.4;
  margin: 0;
}

.slider-pro h6.sp-layer {
  font-size: 11px;
  line-height: 1.4;
  margin: 0;
}

.slider-pro img.sp-layer {
  border: none;
}
