.gallery {
  display: flex;

  flex-direction: row;
  flex-wrap: wrap;

  margin-bottom: rem(56);
  padding-top: rem(32);

  // position: relative;

  &-wrapper {
    &:not(:first-of-type) {
      padding-top: rem(32);
    }

    h2 {
      padding-top: rem(90);
      margin-top: rem(-90);
    }
  }

  &__item,
  .grid-sizer {
    width: calc(100%/2 - 5px);

    @include breakpoint($tablet) {
      width: calc(100%/5 - 8px);
    }
  }

  &__item {
    margin-bottom: rem(10);
  }
}

.virtual-tour {
  margin-top: rem(32);

  h2 {
    padding-top: rem(90);
    margin-top: rem(-90);

    margin-bottom: rem(30);
  }
}
