.availability {
  display: flex;

  flex-direction: column;

  @include breakpoint($desktop) {
    flex-direction: row;
    gap: 20px;
  }

  &__item {
    h2 {
      margin-bottom: rem(18);
    }

    iframe {
      width: 100%;
    }

    @include breakpoint($desktop) {
      flex: 1;
    }
  }
}
