button,
.button {
  display: inline-flex;

  padding: rem(7) rem(16);
  margin-top: rem(20);

  justify-content: center;
  align-items: center;

  color: $color-white;
  font-size: rem(14);
  font-weight: 700;
  line-height: line-height(14, 24);
  letter-spacing: rem(2);
  text-decoration: none;
  text-transform: uppercase;

  opacity: 1;

  background-color: $color-gold;
  border: rem(1) solid $color-gold;
  border-radius: rem(2);

  cursor: pointer;

  transition: background-color 0.2s linear, color 0.2s linear;

  &[disabled] {
    opacity: 0.4;

    &:hover,
    &:focus,
    &:active {
      color: $color-white;
      background-color: $color-gold;
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: $color-white;
    background-color: darken($color-gold, 10%);
  }

  svg {
    margin-left: rem(8);
  }

  &--outline {
    color: $color-gray;

    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
      color: $color-white;

      background-color: $color-gold;
    }
  }

  &[type="submit"] {
    min-width: rem(150);

    padding: rem(20);

    cursor: pointer;
  }

  &--link {
    background: transparent;
    border: none;
    color: $color-gray;
    text-transform: none;
    font-weight: 400;

    &:hover,
    &:active {
      background: transparent;
      color: $color-gray;
      text-decoration: underline;
    }
  }

  .button-group & {
    &:not(:last-of-type) {
      margin-right: rem(10);

      @include breakpoint(1060px) {
        margin-right: rem(32);
      }
    }
  }

  .contact__form & {
    margin-top: rem(12);
  }
}

.button-group {
  margin-top: rem(12);

  &--big {
    display: flex;

    flex-wrap: wrap;
    justify-content: space-around;

    div {
      display: flex;
      width: 100%;

      justify-content: center;

      &:not(:last-child) {
        margin-bottom: rem(20);
      }

      @include breakpoint($tablet) {
        width: 50%;

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }

      a {
        margin-top: 0;
      }
    }

    .pricing & {
      margin-top: 0;
    }
  }
}
