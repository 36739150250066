.header {
  &-group {
    padding-top: rem(75);

    @include breakpoint($tablet) {
      padding-top: 0;
    }
  }

  display: flex;

  padding: rem(24) 0 rem(20) 0;

  text-align: center;
  flex-direction: column;

  position: relative;
  z-index: 2;

  @include breakpoint($tablet) {
    padding: rem(32) 0 rem(20) 0;

    text-align: inherit;
  }

  &__trigger {
    display: block;
    width: 100%;

    padding: rem(15) rem(25);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;

    background-color: $color-white;
    box-shadow: 0 2px 6px rgba(148, 160, 148, 0.16);

    @include breakpoint($tablet) {
      display: none;
    }
  }

  &__info {
    display: flex;
    width: 100%;

    flex-direction: row;
    gap: rem(20);
    justify-content: space-between;

    @include breakpoint($tablet) {
      flex-wrap: wrap;

      padding-bottom: rem(32);

      border-bottom: 1px solid rgba(148, 160, 148, 0.16);
    }

    @include breakpoint($large) {
      flex-wrap: nowrap;
    }
  }

  &__address {
    display: none;

    align-items: start;

    svg {
      margin-right: rem(8);

      color: $color-gold;
    }

    @include breakpoint($tablet) {
      display: flex;
      flex-direction: column;
      width: 47%;
      order: 1;
    }

    @include breakpoint($large) {
      width: 30%;
      order: 1;
    }
  }

  &__social {
    text-align: right;
    display: none;

    align-self: start;

    li {
      &:not(:last-of-type) {
        margin-right: rem(8);
      }

      a {
        padding: rem(5);
      }
    }

    a {
      font-weight: 600;
    }

    @include breakpoint($tablet) {
      display: flex;
      width: 47%;
      order: 2;
      justify-content: flex-end;
    }

    @include breakpoint($large) {
      width: 30%;
      order: 3;
    }
  }

  &__logo {
    width: 100%;

    text-align: center;

    @include breakpoint($tablet) {
      width: 100%;
      order: 3;
    }

    @include breakpoint($large) {
      width: 30%;
      order: 2;
    }

    img {
      width: 100%;
      max-width: rem(260);
      height: auto;

      margin: 0 auto;
    }
  }

  &__content {
    &-group {
      @include breakpoint($tablet) {
        height: rem(80);
      }
    }

    display: flex;
    width: 100%;

    align-items: center;

    background-color: $color-white;

    @include breakpoint($tablet) {
      &.is-sticky {
        width: 100%;
        position: fixed;
        top: 0;
        left: 50%;

        padding-left: 25px;
        padding-right: 25px;

        transform: translate(-50%);

        box-shadow: 0 2px 6px rgba(148, 160, 148, 0.16);

        .header__nav {
          max-width: rem(1160);

          margin: 0 auto;
        }
      }
    }
  }

  &__nav {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;

    flex-wrap: wrap;

    @include breakpoint($tablet) {
      gap: rem(20);

      padding: rem(24) 0;
    }

    @include breakpoint(1186px) {
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    @include breakpoint($tablet, 1185px) {
      justify-content: center;
    }

    &__item {
      position: relative;


      @include breakpoint(0, 767px) {
        width: 100%;

        text-align: left;

        &:not(:last-of-type) {
          margin-bottom: rem(16);
        }
      }

      span,
      a {
        cursor: pointer;

        svg {
          margin-left: rem(8);
          color: $color-gold;
        }
      }


      .sub-nav {
        width: rem(200);

        padding: rem(8) rem(16);

        position: absolute;
        top: 32px;
        left: 0;

        opacity: 0;
        pointer-events: none;

        background-color: $color-white;
        transform: translateY(30px);

        transition: opacity 0.2s linear, transform 0.2s linear;

        a {
          text-transform: none;
          font-weight: 400;
        }

        &.is-shown {
          opacity: 1 !important;
          pointer-events: all;

          transform: translateY(0);
        }

        @include breakpoint(0, 767px) {
          display: flex;
          width: auto;

          position: relative;
          top: 0;

          pointer-events: all;

          padding: 0 rem(16);

          flex-direction: column;
          align-items: flex-start;

          transform: translateY(0);

          opacity: 1 !important;
        }

      }

      &.is-more {
        display: none;

        @include breakpoint($tablet, 1185px) {
          display: list-item;
        }

        .sub-nav {
          left: auto;
          right: 0;

          text-align: right;
        }
      }

      &.hide-on-tablet {
        @include breakpoint($tablet, 1185px) {
          display: none;
        }
      }
    }

    a,
    span {
      text-decoration: none;
      font-weight: 700;
      text-transform: uppercase;
      font-size: rem(14);
      letter-spacing: rem(1);
      line-height: line-height(14, 24);

      opacity: 1;

      transition: color 0.2s linear;

      &:hover {
        color: $color-gold;
      }
    }

    @include breakpoint(0, 767px) {
      width: 100%;
      height: 100%;

      padding: rem(90) rem(25) rem(70);

      position: fixed;
      top: 0;
      left: 0;
      z-index: 4;

      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;

      background-color: $color-white;

      transform: translateX(-100%);

      transition: transform 0.2s linear;

      overflow: scroll;

      &.is-open {
        transform: translateX(0);
      }
    }
  }
}

#nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
  width: 25px;
  height: 19px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: $color-gray;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 7px;
}

#nav-icon1 span:nth-child(3) {
  top: 14px;
}

#nav-icon1.open span:nth-child(1) {
  top: 8px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#nav-icon1.open span:nth-child(3) {
  top: 8px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
