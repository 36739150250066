input,
textarea {
  width: 100%;

  padding: rem(15) rem(20);
  margin-bottom: rem(12);

  letter-spacing: rem(1.5);

  border: rem(1) solid $color-gray;

  transition: border-color 0.2s linear;

  &:focus {
    border-color: $color-gold;
    outline: none;
  }
}

label {
  &.required {
    position: relative;

    &:after {
      content: '*';

      position: absolute;
      right: -10px;
      top: 0;

      line-height: 1;
      font-family: $font-alt;
      color: $color-error;
    }
  }
}

textarea {
  min-height: rem(200);
  margin-bottom: 0;
}

.form-errors {
  color: $color-error;
  font-size: rem(14);
  line-height: 1.3;
}

.form-label {
  font-size: rem(18);
}

.custom-form-control {
  .button {
    display: flex;
    width: 160px;
    height: 160px;

    flex-direction: column;

    background-color: transparent;
    color: $color-gray;
    font-weight: 400;

    margin: 0;

    .svg-inline--fa {
      font-size: rem(30);
      margin: rem(10);
    }

    span {
      font-size: rem(12);
      letter-spacing: 1px;
      text-align: center;
      text-transform: none;
      line-height: 1.2;
      opacity: 0.8;
    }
  }


  position: relative;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked {
    ~ .button {
      font-weight: 700;
      border-color: $color-gray;

      span {
        font-weight: 400;
        opacity: 1;
      }
    }
  }
}

/* Customize the label (the container) */
.custom-form-control-checkbox,
.checkbox_label {
  display: block;
  position: relative;
  padding-left: rem(35);
  margin-top: rem(20);
  cursor: pointer;
  font-size: rem(16);
  line-height: line-height(16, 25);
  user-select: none;
}

.checkbox_label {
  margin-top: 0;
}

/* Hide the browser's default checkbox */
.custom-form-control-checkbox input,
.checkbox_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ccc;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.custom-form-control-checkbox:hover input ~ .checkmark,
.checkbox_label:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-form-control-checkbox input:checked ~ .checkmark,
.checkbox_label input:checked ~ .checkmark {
  background-color: $color-gold;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-form-control-checkbox input:checked ~ .checkmark:after,
.checkbox_label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-form-control-checkbox .checkmark:after,
.checkbox_label .checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
