.two-col {
  display: flex;

  margin-bottom: rem(48);

  flex-direction: column;

  @include breakpoint($tablet) {
    flex-direction: row;
    justify-content: space-between;
    gap: rem(32);

    &.is-reversed {
      flex-direction: row-reverse;
    }
  }

  &__image {
    width: 100%;

    @include breakpoint($tablet) {
      width: 48%;
    }

    @include breakpoint($desktop) {
      width: 100%;
      max-width: 560px;
    }
  }

  &__text {
    display: flex;
    width: 100%;

    margin-top: rem(24);

    flex-direction: column;
    justify-content: center;

    @include breakpoint($tablet) {
      width: 48%;

      margin-top: 0;
    }

    @include breakpoint($desktop) {
      width: 100%;
      max-width: rem(405);
      margin: 0 auto;
    }

    h2 {
      margin-bottom: rem(32);

      font-size: rem(40);
      line-height: 1;
    }

    @include breakpoint($tablet) {
      width: 48%;
    }
  }
}
