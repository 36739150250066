.flash-messages {
  width: 70%;
  background-color: red;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint($tablet) {
    max-width: 90%;
  }

  &__message {
    width: 100%;
    display: flex;
    padding: 0.5rem;
    justify-content: space-between;

    &_type {
      &--error {
        background-color: $color-error;
      }

      &--warning {
        background-color: $color-warning;
      }

      &--success {
        background-color: $color-success;
      }

      &--info {
        background-color: $color-info;
      }
    }

    &_closed {
      display: none;
    }
  }

  &__close_button {
    border: solid 1px $color-white;
    border-radius: 3px;
    background: transparent;
    color: white;
  }
}
