.contact {
  &-group .directions {
    width: 100%;

    padding-top: rem(40);
    padding-bottom: rem(20);

    @include breakpoint($tablet) {
      width: 85%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &-wrapper {
    width: 100%;
  }

  display: flex;

  flex-direction: column;

  @include breakpoint($tablet) {
    flex-direction: row;
    gap: rem(34);
  }

  &__form-group {
    margin-top: rem(34);
    margin-bottom: rem(34);
  }

  &__title {
    margin-bottom: rem(24);
  }

  &__form-group {
    @include breakpoint($tablet) {
      width: 60%;
    }
  }

  &__info {
    @include breakpoint($tablet) {
      flex: 1;
      margin-top: rem(47);
    }

    &-text {
      span {
        font-weight: 600;
      }

      p {
        padding-left: rem(15);
      }
    }
  }

  &__success {
    text-align: center;
    font-size: rem(18);
    font-weight: 600;
    margin: rem(40) 5%;
  }

  &__error {
    text-align: center;
    font-size: rem(18);
    font-weight: 600;
    margin: rem(40) 5%;
  }

  .widget-wrapper {
    width: 100%;
    max-width: rem(470);

    margin: rem(34) auto 0;
  }

  .calendly-inline-widget {
    width: 100%;
    height: 650px;
  }
}
