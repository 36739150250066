.testimonials {
  @include breakpoint($desktop) {
    column-count: 3;
    column-gap: 8.6%;
  }

  &__item {
    font-size: rem(14);
    line-height: line-height(14, 24);

    @include breakpoint($desktop) {
      break-inside: avoid;
    }

    p {
      margin-bottom: rem(16);
    }

    &:not(:last-of-type) {
      margin-bottom: rem(32);
    }
  }

  &__author {
    font-family: $font-alt;
    font-size: rem(16);
    color: $color-gold;
  }

  &-page {
    .testimonials__item {
      text-align: center;
    }
  }

  &__quote {
    font-size: rem(20);
    color: $color-gold;
  }
}
