.user-form-wrapper {
  display: flex;
  flex: 1;

  padding: rem(50) rem(25);

  align-items: center;
  justify-content: center;
}

.user-form {
  flex: auto;
  max-width: rem(500);

  padding: rem(40) rem(20);

  text-align: center;

  border: rem(1) solid $color-white;
  border-radius: rem(5);

  box-shadow: 0 rem(1) rem(3) rgba(0,0,0,0.5);

  #login div {
    display: flex;
    width: 100%;

    flex-direction: column;

    justify-content: center;
    align-items: center;
  }
}

.user-form input {
  width: 100%;
  max-width: rem(350);

  padding: rem(15) rem(25);
  margin-bottom: rem(20);

  font-size: rem(14);
  color: #9d9e9e;
  text-shadow: rem(1) rem(1) 0 rgba(256,256,256,1.0);

  background-color: $color-white;

  border: rem(1) solid $color-white;
  border-radius: rem(5);

  box-shadow: inset 0 rem(1) rem(3) rgba(0,0,0,0.50);

  outline: none;
}

.user-form label {
  display: block;

  margin-bottom: rem(10);

  color: map-get($color-map-admin-user, label);
}

.user-form__title {
  margin: 0 0 rem(30);
}

#password_reset_request_submit,
#login_submit,
#password_reset_token_submit {
  width: 100%;
  max-width: rem(350);

  margin-bottom: rem(20);
  padding: rem(15) rem(25);

  color: $color-white;
  font-size: rem(16);

  background-color: map-get($color-map-admin-user, green);

  border: none;
  border-radius: rem(5);

  outline: none;

  cursor: pointer;
}

#password_reset_request_submit:hover,
#login_submit:hover,
#password_reset_token_submit:hover {
  background-color: darken(map-get($color-map-admin-user, green), 5%);
}

.user-form__link {
  color: map-get($color-map-admin-user, link);

  text-decoration: none;
}

.user-form__link:hover {
  color: map-get($color-map-admin-user, link-hover);

  opacity: 0.7;
}

