/*
  Font variables
*/
$font-main: 'Raleway', sans-serif;
$font-alt: 'Crimson Text', serif;

/*
  Breakpoints
*/
$large: 1200px;
$desktop: 992px;
$tablet: 768px;

/*
  Color and color maps
*/

$color-white: #ffffff;
$color-gold: #b58343;
$color-gray: #404640;
$color-border: #94a094;
$color-error: #9e3329;
$color-warning: orange;
$color-success: darkseagreen;
$color-info: cornflowerblue;
$alert-red: #ffebee !default;
$alert-blue: #e3f2fd !default;
$alert-orange: #fff3e0 !default;
$alert-green: #e8f5e9 !default;
$alert-amber: #fff8e1 !default;

// User login/password reset colors
$color-map-admin-user: (
green: #00ce81,
label:#555555,
link: #808080,
link-hover: #b23b3b
);
