.wizard {
  display: flex;

  flex-direction: column;

  &__content {
    position: relative;
    overflow-y: hidden;
  }

  .disabled-message {
    display: none;
  }

  input:disabled ~ .button .disabled-message {
    display: unset;
    color: #c91a00;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__step {
    display: flex;
    width: 100%;
    height: auto;

    flex-direction: column;

    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    align-items: center;

    transition: opacity 0.2s linear;

    &.is-active {
      opacity: 1;
      z-index: 1;
    }

    &-content {
      display: flex;
      width: 100%;

      margin-top: rem(20);

      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
    }
  }

  &__step-text  {
    text-align: center;

    &-price {
      font-weight: 700;
      font-size: rem(20);
    }
  }

  &__deluxe {
    display: flex;

    flex-direction: column;
    align-items: center;

    &__bottom {
      margin-top: rem(20);
    }
  }

  &__controls {
    display: flex;

    margin-top: rem(34);

    flex-direction: column;

    justify-content: center;

    .button {
      margin: 0;

      &--link {
        margin-top: rem(15);
      }
    }
  }

  &__summary {
    display: flex;
    width: 100%;

    margin-top: rem(20);

    flex-direction: column;

    .table {
      margin: rem(20) auto 0;
    }
  }

  &__progress {
    width: 100%;
    height: rem(28);

    margin-bottom: rem(50);

    position: relative;

    background: #ebedef;
    border-radius: rem(32);

    &-bar {
      width: 0;
      min-width: rem(76);
      height: 100%;

      position: absolute;
      left: 0;
      top: 0;

      background: $color-gold;
      border-radius: rem(32);

      transition: width 0.5s ease-in;
    }

    &-price {
      display: flex;
      width: rem(76);
      height: rem(76);

      position: absolute;
      right: 0;
      top: -23px;

      justify-content: center;
      align-items: center;

      color: $color-white;

      background: $color-gold;
      border-radius: 50%;
    }
  }

  form {
    max-width: rem(500);
    margin: 0 auto;

    .contact__submit-row {
      text-align: center;
    }
  }
}
