:root {

  /* prevents mobile browsers from sometimes scaling text */
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  display: flex;
  min-height: 100vh;

  flex-direction: column;

  font-family: $font-main;
  font-size: rem(16);
  font-weight: 400;
  line-height: line-height(16, 32);
  color: $color-gray;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  background-color: $color-white;

  scroll-behavior: smooth;

  overflow-x: hidden;

  &.menu-open {
    overflow: hidden;
  }
}

main {
  display: flex;
  flex-direction: column;
  flex: 1;

  margin-top: rem(48);

  position: relative;
  z-index: 1;
}

.container {
  display: flex;
  width: 100%;
  max-width: calc(1235px - 25px); // 1160px

  margin: 0 auto;
  padding: 0 rem(25);

  flex-direction: column;
}

.hide {
  display: none;
}

section {
  display: flex;

  flex-direction: column;

  .section-title {
    display: flex;

    margin-bottom: rem(48);

    flex-direction: column;
    align-items: center;

    h1 {
      font-size: rem(40);
      margin-bottom: rem(20);
    }

    .divider {
      max-width: rem(355);
    }

    &__image {
      display: none;

      margin-bottom: rem(32);

      gap: 32px;

      animation: fadeTop 1s 1 cubic-bezier(0.77, 0, 0.175, 1);

      img {
        max-width: rem(500);
      }

      @include breakpoint($tablet) {
        display: flex;
      }
    }
  }
}

@keyframes fadeTop {
  0%   { opacity: 0; transform: translateY(-10%) }

  100% { opacity: 1; transform: translateY(0) }
}

@keyframes fadeLeft {
  0%   { opacity: 0; transform: translateX(-10%) }

  100% { opacity: 1; transform: translateX(0) }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;

  font-family: $font-alt;
  font-weight: 400;
  line-height: 1.3;
  color: $color-gold;

  .section-title & {
    text-align: center;
  }

  a {
    text-decoration: none;

    &:hover {
      color: lighten($color-gold, 10%);
    }
  }
}

h1 {
  font-size: rem(40);
}

h2 {
  font-size: rem(32);
}

h3 {
  font-size: rem(24);
}

ul,
ol {
  padding: 0;
  margin: 0;

  list-style: none;
}

p {
  margin: 0;

  a {
    color: inherit;
    text-decoration: underline;

    opacity: 0.8;

    transition: opacity 0.2s linear, color 0.2s linear;

    &:hover {
      opacity: 1;
    }
  }
}

a {
  color: inherit;
  text-decoration: underline;

  transition: color 0.2s linear;

  &:hover {
    color: lighten($color-gray, 10%);
  }
}

.active {
  color: $color-gold;
}

img {
  display: flex;
  width: 100%;
  max-width: 100%;
  height: auto;
}

hr {
  margin: 0;

  border-top: 1px solid rgba(148, 160, 148, 0.16);
  border-bottom: none;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-bold {
  font-weight: 700;
}

.text-strike {
  text-decoration: line-through;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}
