.instagram {
  padding-top: rem(32);
  margin-bottom: rem(56);

  &__posts {
    display: inline-flex;

    padding-top: rem(32);

    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
  }

  &__item {
    width: 100%;

    transition: opacity 0.2s linear;

    @include breakpoint(520px) {
      width: 48.4%;
    }

    @include breakpoint(800px) {
      width: 32%;
    }

    @include breakpoint($large) {
      width: 24%;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}
