.footer {
  &-group {
    display: flex;

    padding: rem(25) 0 rem(80) 0;

    @include breakpoint(380px) {
      padding: rem(25) 0 rem(65) 0;
    }
  }

  display: flex;

  padding-top: rem(20);
  margin-top: rem(50);

  font-weight: 700;
  letter-spacing: rem(2);

  justify-content: space-between;

  flex-direction: column;

  border-top: rem(1) solid rgba(148, 160, 148, 0.16);

  @include breakpoint(1300px) {
    flex-direction: row;
  }

  p {
    font-size: 12px;

    @include breakpoint($tablet) {
      font-size: 14px;
    }
  }

  &__social {
    display: flex;

    li {
      padding: rem(5);

      &:not(:last-of-type) {
        margin-right: rem(8);
      }
    }
  }
}
