.vendors {
  &__category-title {
    margin-bottom: rem(24);

    font-size: rem(32);
    line-height: 1.3;
  }

  &__item-group {
    display: flex;

    margin-bottom: rem(32);

    flex-direction: column;

    &:not(:first-of-type) {
      padding-top: rem(32);
    }
  }

  &__item-wrapper {
    display: flex;

    flex-direction: column;
    align-items: center;

    text-align: center;

    @include breakpoint($tablet) {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
    }
  }

  &__item {
    &:not(:last-of-type) {
      margin-bottom: rem(24);
    }

    @include breakpoint($tablet) {
      width: 50%;
    }

    @include breakpoint($desktop) {
      width: calc(100%/4)
    }

    h3 {
      font-size: rem(20);
    }
  }
}
