.switch {
  display: flex;

  padding-left: 0;
  gap: rem(10);
  align-items: center;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $color-gold;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $color-gold;
    }

    &:checked + .slider:before {
      transform: translateX(26px);
    }
  }

  .slider {
    display: inline-block;
    width: 60px;
    height: 34px;
    position: relative;
    cursor: pointer;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: .4s;
      border-radius: 50%;
    }
  }
}
