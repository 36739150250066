.table {
  width: 100%;
  max-width: rem(600);

  thead {
    th {
      padding: rem(8);
      background-color: $color-gold;
      color: $color-white;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  tbody {
    th {
      padding: rem(8);
      background-color: $color-gold;
      color: $color-white;
      text-align: center;
    }

    td {
      padding: rem(8);
    }
  }

  tfoot {
    td {
      padding: rem(8);
    }
  }
}
