
$colors: (
background: (
  light: #fff,
  dark: #333333,
),
text: (
  light: #333333,
  dark: #fafafa,
),
background-header: (
  light: #ccc,
  dark: #666,
),
);

@mixin color-themes($map, $themes: (), $defaultLightTheme: light, $defaultDarkTheme: dark) {
  $colorThemes: get-theme-colors($map, $themes);

  @if map-has-key($colorThemes, $defaultLightTheme) == false {
    $defaultTheme: nth(map-keys($colorThemes), 1);
  }

  @if map-has-key($colorThemes, $defaultDarkTheme) == false {
    $defaultTheme: nth(map-keys($colorThemes), 2);
  }

  @each $theme, $colors in $colorThemes {
    @if $theme == $defaultLightTheme {
      body.theme-#{$theme}, :root {
        @each $key, $value in $colors {
          --#{$key}: #{$value};
        }
      }
    } @else {
      body.theme-#{$theme} {
        @each $key, $value in $colors {
          --#{$key}: #{$value};
        }
      }
    }
  }

  @media (prefers-color-scheme: dark) {
    @each $theme, $colors in $colorThemes {
      @if $theme == $defaultDarkTheme {
        body.theme-#{$theme}, :root {
          @each $key, $value in $colors {
            --#{$key}: #{$value};
          }
        }
      } @else {
        body.theme-#{$theme} {
          @each $key, $value in $colors {
            --#{$key}: #{$value};
          }
        }
      }
    }
  }
}

@function get-theme-colors($map, $themes: ()) {
  @if length($themes) == 0 {
    $themesMap: ();
    @each $variableName, $values in $map {
      $themesMap: map-merge($themesMap, $values)
    }

    $themes: map-keys($themesMap)
  }

  $themeValues: ();
  @each $theme in $themes {
    $colorMap: ();

    @each $variableName, $values in $map {
      $colorMap: map-merge($colorMap, ($variableName: map-get($values, $theme)));
    }

    $themeValues: map-merge($themeValues, ($theme: $colorMap));

  }

  @return $themeValues;
}

@include color-themes($colors);

@function color($name) {
  @if map-has-key($colors, $name) == false {
    @error "Color #{$name} does not exists."
  }

  @return var(--#{$name});
}

